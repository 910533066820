export interface Theme {
  Background: string;
  Header: string;
  Body: string;
  PrimaryColor: string;
  TertuaryColor: string;
  ButtonHover: string;
  Border: string;
}

export const theme1: Theme = {
  Background: "#F3E8EE",
  Header: "#15141A",
  Body: "#15141A",
  PrimaryColor: "#729B79",
  TertuaryColor: "#D56F3E",
  ButtonHover: "#729B79",
  Border: "#729B79",
};
